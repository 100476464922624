<template>
  <div>
    <div class="m-4">
      <div class="clearfix">
        <div>
          <vs-row vs-w="12">
            <vs-col
              class="pr-4"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="12"
            >
              <div class="w-full">
                <vs-input
                  type="date"
                  class="inputx w-full mb-2 mt-2"
                  label="Date de paiement*"
                  :max="maxDatePayments"
                  v-model="form.paymentDate"
                  data-vv-validate-on="blur"
                  name="Date de paiement"
                />
                <span class="text-danger text-sm">{{
                  errors.first("Date de paiement")
                }}</span>
              </div>
            </vs-col>

            <vs-col
              class="pr-4"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-sm="6"
              vs-xs="12"
            >
              <div class="w-full">
                <vs-input
                  type="number"
                  class="aps-benin-v2-form-number inputx w-full mt-2 mb-2"
                  label="Cotisation TTC*"
                  v-model="form.cotisationTTC"
                  v-validate="'required|numeric'"
                  data-vv-validate-on="blur"
                  name="Cotisation TTC"
                  :disabled="(byProductContent.isDefault == true && byProject.notice == 'validationWithExtraPremium') ? false : true" 
                />
                <div class="aps-benin-v2-error-msg">
                  {{ errors.first("Cotisation TTC") }}
                </div>
              </div>
            </vs-col>

            <vs-col
              class="pr-4"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >
              <div class="w-full">
                <span class="pl-2">Mode*</span>
                <multiselect
                  v-model="form.mode"
                  :options="modeList"
                  placeholder="--Mode--"
                  label="label"
                  track-by="label"
                  v-validate="'required'"
                  name="Mode"
                  required
                  :selectLabel="global.selectLabel"
                  :selectedLabel="global.selectedLabel"
                  :deselectLabel="global.deselectLabel"
                >
                  <template v-slot:noOptions>
                    <span>{{ global.selectNotFoundData }}</span>
                  </template>
                </multiselect>

                <span class="text-danger text-sm">{{
                  errors.first("Mode")
                }}</span>
              </div>
            </vs-col>

            <vs-col
              class="pr-4"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="12"
              vs-sm="12"
              vs-xs="12"
            >
              <div class="w-full pt-4">
                <span class="pl-2">Commentaire*</span>
                <vs-textarea
                  v-model="form.comment"
                  label=""
                />
              </div>
            </vs-col>
          </vs-row>
        </div>
      </div>
      <div class="mt-8">
        <div class="inline-flex">
          <div>
            <vs-button
              class="w-full sm:w-auto"
              :disabled="!validateForm || loading"
              @click="fetchApplyPay"
              >Valider</vs-button
            >
            <vs-button
              class="w-full sm:w-auto ml-4"
              type="border"
              @click="exitPayment"
              >Annuler</vs-button
            >
          </div>
          <div class="ml-2">
            <clip-loader :loading="loading" color="primary"></clip-loader>
          </div>
        </div>
        <div class="mt-6">
          <p class="text-danger">* Champ obligatoire</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { en, fr } from "vuejs-datepicker/dist/locale";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { mixin } from "@/mixins/mixin";
import role from "@/config/role";
import moment from "@/helpers/moment";
import global from "@/config/global";
import Multiselect from "vue-multiselect";

export default { 
  name: "aps-benin-v2-subscribe-mark-payment",
  props: {
    item: Object,
    isClo: Boolean,
    crrModuleName: String,
    byProject: Object,
    byProductContentSelected: Object,
    isMultiple: Boolean,
    crrIDs: Array,
    cumulAmountPayed: Number,
  },
  data() {
    return {
      loading: false,
      byProductContent: {},
      maxDatePayments: "",
      form: {
        paymentDate: "",
        mode: "",
        comment: "",
        cotisationTTC: 0,
      },
      modeList: [],
      loadingDocTempoDeces: false,
      tempo_deces_xls_doc: "",
      cotisationDefaultList: [],
    };
  },
  created() {
    this.global = global;
  },
  mounted() {
    this.modeList = this.paymentMethod;
    this.maxDatePayments = moment().format("YYYY-MM-DD")
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    ...mapGetters("general", ["paymentMethod", "allDocClient",]),
    validateForm() {
      return (
        !this.errors.any() &&
        this.form.mode != "" &&
        this.form.cotisationTTC != 0 &&
        this.form.paymentDate != "" && this.checkMaxDateContributions(this.form.paymentDate) <= 0
      );
    },
  },
  methods: {
    ...mapActions("auth", ["logout"]),
    ...mapActions("general", ["applyGetItemDocument",]),
    ...mapActions("suscribe", [
      "applyPutProject",
      "applyChangeProjectState",
      "applyPutAvenant",
      "applyChangeAvenantState",
      "applyChangeQuittanceState",
      "applyPutAllQuittances",
    ]),
    applyGetDefaultPayment(project) {
      if(project.productName == "SOHU") {
        this.byProductContent = {...project.getDefaultAvenant}
      }
    },
    checkMaxDateContributions(end) {
      return mixin.methods.checkDiffDay(
        moment(new Date()).format("YYYY-MM-DD"),
        end
      );
    },
    currentDate() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      var yyyy = today.getFullYear();
      this.form.paymentDate = yyyy + "-" + mm + "-" + dd;
    },

    exitPayment() {
      this.$emit("onDisplayMarkPayment", false);
    },
    checkUserRole(pack) {
      return role[this.user.type][pack];
    },
    dateElement(data) {
      return mixin.methods.dateElement(data);
    },
    acceptAlert(data) {
      let newdata = { ...data };
      this.$vs.notify(newdata);
    },
    async fetchTarifsDocContent(data) {
      this.loadingDocTempoDeces = true;

      try {
        let resItemDoc = await this.applyGetItemDocument({
          code: "tarifs_sohu",
          productName: this.allDocClient[this.byProject.productName],
        });

        this.tempo_deces_xls_doc = resItemDoc.data
          ? resItemDoc.data.filename
          : "";

        this.byGetTarifsContentFile();

        this.loadingDocTempoDeces = false;
        this.error = false;
      } catch (err) {
        this.loadingDocTempoDeces = false;
        this.error = true;
        console.log("err", err);
      }
    },

    byGetTarifsContentFile() {
      mixin.methods
        .getAmountAndCotisationAPF(this.tempo_deces_xls_doc)
        .then((res) => {
          if (res.Cotisation) {
            this.cotisationDefaultList = []
            res.Cotisation.forEach((el) => {
              this.cotisationDefaultList.push({
                id: el,
                label: el,
              });
            });

            console.log(
              "cotisationDefaultList FILE EXCELL",
              this.cotisationDefaultList
            );
          }
        });
    },
    async fetchApplyPay() {
      if (!this.validateForm) return false;
      this.loading = true;

      let h = new Date().getHours() 
      let m = new Date().getMinutes() 

      if(h.toString().length <= 1) {
        h = "0" + h.toString()
      }
      if(m.toString().length <= 1) {
        m = "0" + m.toString()
      } 

      try {
        if (this.crrModuleName == "ContractIssue") {
          let data = {
            avenantId: this.byProductContent.id,
            //quittanceId: this.byProductContent.id,
            paymentStatus: "payOff",
            cotisationTTC: this.form.cotisationTTC,
            paymentAmount: this.form.cotisationTTC,
            payNow: true,
            paymentRequest: {
              type: this.form.mode.id,
              data: {
                userType: this.user.type,
                userId: this.user.id,
                userFullname: this.user.person.firstname + " " + this.user.person.lastname,
                datePayment: moment(this.form.paymentDate+"T"+h+":"+m).format("D MMMM YYYY à HH:mm"),
                comment: this.form.comment,
              },
            }, 
          }

          console.log('data', data.paymentRequest)
          if(this.byProject.productName == "SOHU") {
            await this.applyChangeAvenantState(data)
          }


          this.loading = false;
          this.acceptAlert(
            mixin.methods.message("subscription", "payement").success
          );
          this.form = {
            paymentDate: "",
            mode: "",
            comment: "",
            cotisationTTC: "",
          };

          this.exitPayment();
        } else if (this.crrModuleName == "Avenant") {
          let data = {
            avenantId: this.byProductContent.id,
            paymentStatus: "payOff",
            cotisationTTC: this.form.cotisationTTC,
            paymentAmount: this.form.cotisationTTC,
            payNow: true,
            paymentRequest: {
              type: this.form.mode.id,
              data: {
                userType: this.user.type,
                userId: this.user.id,
                userFullname: this.user.person.firstname + " " + this.user.person.lastname,
                datePayment: moment(this.form.paymentDate+"T"+h+":"+m).format("D MMMM YYYY à HH:mm"),
                comment: this.form.comment,
              },
            },
          };
          await this.applyChangeAvenantState(data);
          this.loading = false;
          this.acceptAlert(
            mixin.methods.message("subscription", "payement").success
          );
          this.form = {
            paymentDate: "",
            mode: "",
            comment: "",
            cotisationTTC: "",
          };

          this.exitPayment();
        } else if (this.crrModuleName == "Quittance") {
          let data = {
            isMultiple: this.isMultiple,
            quittanceIDs: this.crrIDs,
            quittanceId: this.byProductContent ? this.byProductContent.id : null,
            paymentStatus: "payOff",
            cotisationTTC: this.form.cotisationTTC,
            payNow: this.isMultiple ? false : true,
            paymentRequest: {
              type: this.form.mode.id,
              data: {
                userType: this.user.type,
                userId: this.user.id,
                userFullname: this.user.person.firstname + " " + this.user.person.lastname,
                datePayment: moment(this.form.paymentDate+"T"+h+":"+m).format("D MMMM YYYY à HH:mm"),
                comment: this.form.comment,
              },
            },
          };
          await this.applyChangeQuittanceState(data)
          this.loading = false;
          this.acceptAlert(
            mixin.methods.message("subscription", "payement").success
          );
          this.form = {
            paymentDate: "",
            mode: "",
            comment: "",
            cotisationTTC: "",
          };

          this.exitPayment();
        }
      } catch (err) {
        this.loading = false;
        this.error = true;
        this.errorMsg = "";

        if (err.response) {
          if (
            err.response.status &&
            err.response.statusText === "Unauthorized"
          ) {
            this.acceptAlert(
              "danger",
              "Authentification",
              "Votre session a expirée"
            );
            await this.logout();
            this.$router.push({ name: "Login" });
          } else {
            this.acceptAlert(
              mixin.methods.message("subscription", "payement").error
            )
          }
        }
      }
    },
  },
  components: {
    ClipLoader,
    Multiselect,
  },
  watch: {
    isClo() {
      if (this.crrModuleName == "ContractIssue") {
        this.applyGetDefaultPayment(this.byProject)
      } else {
        if (this.isMultiple) { //Multiple paiement sur liste des quittances
          this.form.cotisationTTC = this.cumulAmountPayed
        } else {
          this.byProductContent = this.byProductContentSelected
        }
      }
    },
    byProductContent() {
      if (this.byProductContent) {
        this.form.cotisationTTC = this.byProductContent.cotisationTTC;
      }
    },
    'form.paymentDate' () {
    },
  },
};
</script>
